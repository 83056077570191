import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContReduceCostos,
  TextReduceCostos,
  TitleReduceCostos,
  ContButton,
  ContReduceCostosInner, ContImgReduceCostos, ContInfoReduceCostos,
} from './ReduceCostos.styles';
import {Grid} from '@mui/material';
import ButtonSpecial from '../ButtonSpecial';
import Image from '../../assets/images/beneficios-marcas.webp';

export const ReduceCostos = () => {
  const { t } = useTranslation();
  return (
    <ContReduceCostos id={'ContReduceCostos'}>
      <ContReduceCostosInner className={'container-fit z-index-cont'} id={'ContReduceCostosInner'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={5} order={{ xs: 1, sm: 1, md: 1 }}>
            <ContInfoReduceCostos>
              <TitleReduceCostos>
              {t('Reduce costos de operación')}
              </TitleReduceCostos>
              <TextReduceCostos>
              {t('Con nuestra plataforma no necesitas contratar a terceros, encontrarás a los mejores proveedores para hacer más sencillos y rápidos tus procesos.')}
              </TextReduceCostos>
              <ContButton>
                <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={''} theme={'secondary'}/>
              </ContButton>
            </ContInfoReduceCostos>
          </Grid>
          <Grid item xs={12} sm={12} md={7} order={{ xs: 2, sm: 2, md: 2 }}>
            <ContImgReduceCostos>
              <img src={Image} alt={'Partners financieros Dynamicore'} height={'auto'}/>
            </ContImgReduceCostos>
          </Grid>
        </Grid>
      </ContReduceCostosInner>
    </ContReduceCostos>
  )
}
