import { createGlobalStyle } from 'styled-components'

import Eina03RegularEot from '../fonts/Eina03Regular.eot';
import Eina03RegularTtf from '../fonts/Eina03Regular.ttf';
import Eina03RegularWoff from '../fonts/Eina03Regular.woff';
import Eina03RegularWoff2 from '../fonts/Eina03Regular.woff2';
import Eina03RegularSvg from '../fonts/Eina03Regular.svg';

import Eina03BoldEot from '../fonts/Eina03Bold.eot';
import Eina03BoldTtf from '../fonts/Eina03Bold.ttf';
import Eina03BoldWoff from '../fonts/Eina03Bold.woff';
import Eina03BoldWoff2 from '../fonts/Eina03Bold.woff2';
import Eina03BoldSvg from '../fonts/Eina03Bold.svg';

export const FontFiles = createGlobalStyle`
  @font-face {
    font-family: 'Eina03Regular';
    src: url(${Eina03RegularEot});
    src: local('Eina03Regular'), local('Eina03Regular'),
        url(${Eina03RegularWoff2}) format('woff2'),
        url(${Eina03RegularWoff}) format('woff'),
        url(${Eina03RegularTtf}) format('truetype'),
        url('${Eina03RegularSvg}#Eina03Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Eina03Bold';
    src: url(${Eina03BoldEot});
    src: local('Eina03Bold'), local('Eina03Bold'),
        url(${Eina03BoldWoff2}) format('woff2'),
        url(${Eina03BoldWoff}) format('woff'),
        url(${Eina03BoldTtf}) format('truetype'),
        url('${Eina03BoldSvg}#Eina03Bold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
`

export const GlobalFonts = {
  fontPrimary: 'Eina03Regular',
  fontSecondary: 'MulishMedium',
  fontLight: 'Eina03Regular',
  fontRegular: 'Eina03Regular',
  fontMedium: 'Eina03Bold',
  fontBold: 'Eina03Bold',
  fontExtraBold: 'Eina03Bold',
  fontBlack: 'Eina03Bold',
}

